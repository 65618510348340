import { navigate } from "gatsby"
import React, { useState } from "react"
import { Layout } from "../../components/Layout"
import { SinglePatient } from "../../components/SinglePatient"
import { PatientsSection } from "../../style/patients"
import DashboardData from "../../components/DashboardData"
import DateRangePickerComp from "../../components/DateRangePicker"
import Overlay from "../../components/Overlay"

function Patient({ location = {} }) {
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  if (location.state === null || undefined) {
    // console.log(location.state)
    navigate("/patients")
  }

  // console.log("patient from navigation state", location.state)

  return (
    <div>
      <Layout>
        <PatientsSection>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <p
              onClick={() => {
                navigate("/patients")
                // ;((location.state.data.isPrimary === true ||
                //   location.state.data.isAgent === true) &&
                //   typeof history !== "undefined" &&
                //   history.go(-1)) ||
                //   (typeof history !== "undefined" && history.go(-2))
              }}
              style={{
                cursor: "pointer",
                // textDecoration: "underline",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Patients
            </p>
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.9rem",
                marginBottom: ".9rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              {" "}
              /{/* &#8827; */}
            </p>
            {typeof location.state !== `undefined` &&
              location.state.data.isPrimary === undefined && (
                <>
                  <p
                    onClick={() => {
                      typeof history !== "undefined" && history.go(-1)
                    }}
                    style={{
                      cursor: "pointer",
                      // textDecoration: "underline",
                      fontSize: "0.7rem",
                      marginBottom: "1rem",
                    }}
                  >
                    Primary Patient
                  </p>
                  <p
                    style={{
                      // cursor: "pointer",
                      fontSize: "0.9rem",
                      marginBottom: ".9rem",
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    }}
                  >
                    {" "}
                    /{/* &#8827; */}
                  </p>
                </>
              )}
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Patient ID: {location.state ? location.state.data.patientId : " "}
            </p>
          </div>
          <h1>
            {" "}
            {typeof location.state !== `undefined` &&
              location.state.data.isAgent === true
              ? "Health Coach"
              : "patient"}{" "}
            -{" "}
            <span
              style={{
                backgroundColor: "yellow",
              }}
            >
              {location.state
                ? location.state.data.firstName +
                " " +
                location.state.data.lastName
                : " "}
            </span>
          </h1>
          <SinglePatient patientData={location.state && location.state.data} />
          {typeof location.state !== `undefined` && location.state && <DashboardData
            onCustomPress={(bool) => { setShowRangePicker(bool) }}
            range={range[0]}
            isRangeSelected={!showRangePicker}
            patientId={location.state.data.patientId}
          />}
        </PatientsSection>
        {showRangePicker && <DateRangePickerComp
          rangeSelected={(dateRange) => { setRange(dateRange); }}
          onDonePressed={() => { setShowRangePicker(false); }}
          startDate={range[0].startDate}
          endDate={range[0].endDate}
        />}
        <Overlay open={showRangePicker} close={() => { setShowRangePicker(false) }} />
      </Layout>
    </div>
  )
}

export default Patient
