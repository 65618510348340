import React from "react"
import { Table } from "./Table"
import { TableStyles } from "../style/globalStyles"
import Moment from "moment"

export const SecPatientTable = ({ patients }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "patientId",
      },
      {
        Header: "Name",
        accessor: (patient) =>
          patient.firstName ? `${patient.firstName} ${patient.lastName}` : "NA",
      },
      {
        Header: "Relation",
        accessor: (patient) =>
          patient.relationDesc ? patient.relationDesc : "NA",
      },
      {
        Header: "Gender",
        accessor: (patient) => (patient.genderDesc ? patient.genderDesc : "NA"),
      },
      {
        Header: "Date of Birth",
        accessor: (patient) =>
          patient.age ? Moment(patient.age).format("DD MMM YYYY") : "NA",
      },
      {
        Header: "Phone Number",
        accessor: (patient) => (patient.mobileNo ? patient.mobileNo : "NA"),
      },
      {
        Header: "City",
        accessor: (patient) => (patient.city ? patient.city : "NA"),
      },
      {
        Header: "Active",
        accessor: (isActive) => {
          return isActive.isActive ? "Active   " : "Not Active    "
        },
      },

      {
        Header: "Primary",
        accessor: (isPrimary) => {
          return isPrimary.isPrimary ? "Primary   " : "Not Primary    "
        },
      },
    ],
    []
  )

  var data = React.useMemo(() => patients, [])

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e) => console.log("row clicked", rowInfo),
        }
      }}
      // Pass the page url here to navigate to onClick
      // healthCoach={true}
      path={"/patients/patient"}
      columns={columns}
      data={data}
    />
  )

  return <TableStyles>{patients ? tableRender : " "}</TableStyles>
}
