import React, { useState, useEffect } from "react"
import { Form } from "../style/globalStyles"
import Switch from "react-switch"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { fetchSecondaryPatients, updatePatient } from "../state"
import { SecPatientTable } from "./SecPatientTable"
import Moment from "moment"

export const SinglePatient = ({ patientData = {} }) => {
  const user = useSelector((state) => state.login.user)
  patientData === null && typeof history !== "undefined" && history.go(-1)
  const [state, setState] = useState({ patientData })
  // console.log(state)
  const secondaryPatients = useSelector(
    (state) => state.patients.secondaryPatients
  )
  // console.log(typeof history !== "undefined" && history)
  const dispatch = useDispatch()
  const [enable, setEnable] = useState(false)
  useEffect(() => {
    if (patientData !== {}) {
      setState(patientData)
      if (typeof patientData.isPrimary === undefined) {
        return
      } else if (
        typeof patientData.isAgent !== undefined &&
        patientData.isAgent === true
      ) {
        dispatch(fetchSecondaryPatients({ agentId: patientData.agentId }, user))
        return
      } else if (typeof patientData.isPrimary !== undefined) {
        dispatch(
          fetchSecondaryPatients({ patientId: patientData.patientId }, user)
        )
      }
      // setState({...state, secondaryPatients: })
    } else {
      navigate("/patients")
    }
    // console.log("useEffect state", state)
  }, [patientData])

  const SecondaryPatients =
    secondaryPatients &&
    secondaryPatients.map((secPatient) => (
      <div
        onClick={() => {
          var agentFlag = false
          if (patientData.isAgent) agentFlag = true
          secPatient.isAgent === false &&
            navigate("/patient", {
              state: {
                data: { ...secPatient, agentFlag: agentFlag },
              },
            })
        }}
        style={{
          width: "20rem",
          margin: "1rem 0",
          display: "flex",
          cursor: "pointer",
        }}
      >
        <p
          style={{
            textTransform: "uppercase",
            padding: "0 1rem 0 0",
            margin: "0",
            color: secPatient.isAgent === true ? "gray" : "blue",
            fontSize: ".8rem",
          }}
        >
          {secPatient.firstName} {secPatient.lastName}
        </p>
        {secPatient.isAgent === true ? (
          <p style={{ fontSize: ".7rem" }}>( Health Coach )</p>
        ) : (
          secPatient.relationDesc && (
            <p style={{ fontSize: ".7rem" }}>( {secPatient.relationDesc} )</p>
          )
        )}
      </div>
    ))
  // console.log(SecondaryPatients)

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setEnable(true)
  }
  const handleActiveChange = (value) => {
    setState({ ...state, isActive: value })
    setEnable(true)
  }
  const handlePrimaryChange = (value) => {
    setState({ ...state, isPrimary: value })
    setEnable(true)
  }
  //   const handleSubscribeChange = (value) => {
  //     setState({ ...state, isSubscription: value })
  //     setEnable(true)
  //   }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (enable) {
      dispatch(updatePatient(state))
      // console.log("patient update api request can be sent")
    }
    return
  }

  return (
    <>
      <Form onSubmit={handleSubmit} style={{}}>
        <div style={{ paddingRight: "1rem" }}>
          <div
            style={{
              marginBottom: "1rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              borderBottom: "1px solid lightgray",
            }}
          >
            <label htmlFor="firstname">
              First Name
              <p>{patientData.firstName ? patientData.firstName : "NA"}</p>
            </label>
            <label>
              Last Name
              <p>{patientData.lastName ? patientData.lastName : "NA"}</p>
            </label>
            <label htmlFor="patientId">
              patient ID
              <p>{patientData.patientId}</p>
            </label>
            <label htmlFor="age">
              Age
              <p>
                {patientData.age ? patientData.age : "NA"}
                {/* {patientData.age
                  ? Moment(patientData.age).format("DD MMM YYYY")
                  : "NA"} */}
              </p>
            </label>

            <label htmlFor="mobileNo">
              Mobile Number
              <p>{patientData.mobileNo ? patientData.mobileNo : "NA"}</p>
            </label>
            <label htmlFor="email">
              Email
              <p>{patientData.email ? patientData.email : "NA"}</p>
            </label>
            <p />
          </div>
          <div
            style={{
              marginBottom: "2rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <label htmlFor="addressLine1">
              Address Line 1
              <p>
                {patientData.addressLine1 ? patientData.addressLine1 : "NA"}
              </p>
            </label>

            <label htmlFor="city">
              City
              <p>{patientData.city ? patientData.city : "NA"}</p>
            </label>
            <label htmlFor="state">
              State
              <p>{patientData.state ? patientData.state : "NA"}</p>
            </label>
            <label htmlFor="pincode">
              Pincode
              <p>{patientData.pinCode ? patientData.pinCode : "NA"}</p>
            </label>
            <label htmlFor="country">
              Country
              <p>{patientData.country ? patientData.country : "NA"}</p>
            </label>
          </div>
          <div>
            <h3 style={{ color: "gray" }}>
              {" "}
              {patientData.isAgent === true && patientData.agentId != null
                ? "Families under this Health coach"
                : "FAMILIY MEMBERS"}
            </h3>
            {patientData.isAgent === false &&
              patientData.agentId != null &&
              patientData.agentFlag === true && (
                <p
                  style={{
                    fontSize: ".9rem",
                    marginTop: "1rem",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    typeof history !== "undefined" && history.go(-1)
                  }}
                >
                  Health Coach
                </p>
              )}
            <div>
              {SecondaryPatients && SecondaryPatients.length !== 0 ? (
                <SecPatientTable patients={secondaryPatients} />
              ) : (
                // SecondaryPatients
                <p
                  style={{
                    fontSize: ".8rem",
                    marginTop: "1.5rem",
                  }}
                >
                  No Family members
                </p>
              )}
              <p>
                {!patientData.isPrimary && (
                  <>
                    {/* <p>This is a secondary patient profile</p> */}
                    <p
                      style={{
                        fontSize: ".9rem",
                        marginTop: "1rem",
                        color: "gray",
                        // cursor: "pointer",
                      }}
                      // onClick={() => {
                      //   typeof history !== "undefined" && history.go(-1)
                      // }}
                    >
                      This is a secondary patient profile
                    </p>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            height: "fit-content",
          }}
        >
          <label htmlFor="isActive">
            Active Status
            <br />
            <br />
            <Switch
              name="isActive"
              className="react-switch"
              onChange={handleActiveChange}
              checked={state.isActive}
            />
          </label>
          <label htmlFor="isPrimary">
            Primary Status
            <br />
            <p>{state.isPrimary ? "Primary" : "Non-Primary"}</p>
          </label>
          <label htmlFor="created">
            Created
            <p>
              {patientData.created &&
                Moment(patientData.created, "DD-MM-YYYY").format("DD MMM YYYY")}
            </p>
          </label>
          <label htmlFor="updated">
            Last updated
            <p>
              {patientData.updated
                ? Moment(patientData.updated, "DD-MM-YYYY").format("DD MMM YYYY")
                : "NA"}
            </p>
          </label>
          <br />
          <button
            type="submit"
            aria-disabled={enable ? true : false}
            style={{
              backgroundColor: enable ? "green" : "lightgray",
              cursor: enable ? "pointer" : "default",
            }}
          >
            Save Changes
          </button>
        </div>
      </Form>
      {/* <>
        <h3 style={{ color: "gray" }}>
          {" "}
          {patientData.isAgent === true && patientData.agentId != null
            ? "Families under this Health coach"
            : "FAMILIY MEMBERS"}
        </h3>
        {patientData.isAgent === false &&
          patientData.agentId != null &&
          patientData.agentFlag === true && (
            <p
              style={{
                fontSize: ".9rem",
                marginTop: "1rem",
                color: "blue",
                cursor: "pointer",
              }}
              onClick={() => {
                typeof history !== "undefined" && history.go(-1)
              }}
            >
              Health Coach
            </p>
          )}
        <div>
          {SecondaryPatients && SecondaryPatients.length !== 0 ? (
            <SecPatientTable patients={secondaryPatients} />
          ) : (
            // SecondaryPatients
            <p
              style={{
                fontSize: ".8rem",
                marginTop: "1.5rem",
              }}
            >
              No Family members 
            </p>
          )}
          <p>
            {!patientData.isPrimary && (
              <>
                <p>This is a secondary patient profile</p>
                <p
                  style={{
                    fontSize: ".9rem",
                    marginTop: "1rem",
                    color: "gray",
                    cursor: "pointer",
                  }}
                  // onClick={() => {
                  //   typeof history !== "undefined" && history.go(-1)
                  // }}
                >
                  This is a secondary patient profile
                </p>
              </>
            )}
          </p>
        </div>
      </> */}
    </>
  )
}
